import * as React from "react"
import { graphql } from "gatsby"
import FrontmatterPageContext from "~context/FakePageContext"
import LayoutSectionIndex from "~templates/layout-section_index"
import DisplayGuidesInPrimary, { primary_orphans_to_cards } from "~components/guides_in_primary"
import NavData from "~data/nav_fundamentals.yml"
import { TranslationJA } from "~components/includes"
import { SeoHead } from "~components/Head/SeoHead"
import { FavHead } from "~components/Head/FavHead"

// these came from the /ja/fundamentals/index.md page
let frontmatter = {
  lang: "ja",
  section: "fundamentals",
  section_safe: "fundamentals",
  home_url: "/",
  section_url: "/ja/fundamentals/",
  crumb2override: "基礎",
  fileslug: "",
  title: "基礎 | Fastly Documentation",
  i18n: ["en"],
}

const JaFundamentalsIndexPage = ({ data, location }) => {
  let PrimaryDatas = []

  NavData.forEach((element) => {
    const { crumb: pkey } = element

    let thisPrimary = data.primaries_en_f.nodes.filter((nod) => nod.frontmatter.cat_id === pkey)[0]
    let p_frontmatter = thisPrimary.frontmatter

    // its ugly, but we can hardcode this since tihs is only used on this
    p_frontmatter["section_url"] = "/ja/fundamentals/"

    let myOrphans = data.primary_orphans_f.group.find((g) => g.fieldValue === pkey)

    PrimaryDatas.push({
      pkey: pkey,
      pfrontmatter: p_frontmatter,
      sec_cards: [],
      primary_orphans: primary_orphans_to_cards(p_frontmatter, myOrphans),
    })
  })

  return (
    <>
      <FrontmatterPageContext.Provider value={frontmatter}>
        <LayoutSectionIndex location={location}>
          {PrimaryDatas.map((datum) => {
            const { pfrontmatter, sec_cards, primary_orphans } = datum
            return (
              <DisplayGuidesInPrimary
                frontmatter={pfrontmatter}
                sec_cards={sec_cards}
                primary_orphans={primary_orphans}
              />
            )
          })}
          <TranslationJA />
        </LayoutSectionIndex>
      </FrontmatterPageContext.Provider>
    </>
  )
}

export default JaFundamentalsIndexPage

export const query = graphql`
  query {
    fundamentals_in_primarys: allMdx(
      filter: {
        fileAbsolutePath: { regex: "/_ja_fundamentals//" }
        frontmatter: { unlisted: { ne: true }, published: { ne: false } }
      }
    ) {
      group(field: frontmatter___primary_nav) {
        field
        fieldValue
        totalCount
        nodes {
          slug
          frontmatter {
            primary_nav
            secondary_nav
            header
          }
        }
      }
    }
    primaries_en_f: allMdx(filter: { fileAbsolutePath: { regex: "/_ja_fundamentals_primary/" } }) {
      nodes {
        fileAbsolutePath
        frontmatter {
          cat_id
          section_name
          svg
          description
        }
      }
    }
    primary_orphans_f: allMdx(
      filter: {
        frontmatter: {
          # orphan: {eq: true}
          unlisted: { ne: true }
        }
        fileAbsolutePath: { regex: "/_ja_fundamentals//" }
      }
      sort: { fields: fileAbsolutePath }
    ) {
      group(field: frontmatter___primary_nav) {
        field
        fieldValue
        nodes {
          fileAbsolutePath
          slug
          excerpt
          frontmatter {
            primary_nav
            # secondary_nav
            header
            # unlisted
            # orphan
          }
        }
      }
    }
  }
`

export const Head = (props) => {
  return <>
    <title>{frontmatter.title}</title>
    <SeoHead frontmatter={frontmatter} {...props} />
    <FavHead />
  </>
}
